import spices from '../spices.jpg'

export const Home = () =>{
return(<div className="bg-image"
     style={{
     backgroundImage: `url(${spices})`, backgroundPosition: 'center',
     backgroundSize: 'cover',
     backgroundRepeat: 'no-repeat',
     width: '100vw',
     height: '100vh',
     overflow: 'hidden'
     }}>
    <div className='container-fluid'>
          <div className='row'>
            <div className='col-md-4'>
                 <h1 style={{marginTop:'100px',color:'white'}}>Bhagyalakshmi Spices</h1>
                 <h4 style={{color:'white'}}>SVG Market, Rajahmundry - 533103</h4>
                 <h4 style={{marginTop:'10px',color:'white'}}>Coming Soon.....</h4>
            </div>
          </div>
    </div>
</div>);
}