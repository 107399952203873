import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Home } from './components/Home';

function App() {
  return (
    <div className="App">
     <Home/>
    </div>
  );
}

export default App;
